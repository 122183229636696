import {
  FaIconLibrary,
  FontAwesomeModule,
} from "@fortawesome/angular-fontawesome";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { faFolder, faTrophy } from "@fortawesome/free-solid-svg-icons";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { Device } from "@ionic-native/device/ngx";
// FCM
//import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";
import { FormsModule } from "@angular/forms";
import { GalleryModule } from "@ngx-gallery/core";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { IonicModule } from "@ionic/angular";
import { IonicStorageModule } from "@ionic/storage";
import { LanguagesPopoverPageModule } from "./pages/languages-popover/languages-popover.module";
import { LightboxModule } from "@ngx-gallery/lightbox";
import { Network } from "@ionic-native/network/ngx";
import { NgModule } from "@angular/core";
import { NgxPopperModule } from "ngx-popper";
import { NotificationFilterPage } from "./components/notification-filter/notification-filter";
import { Push } from "@ionic-native/push/ngx";
import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
import { ServiceWorkerModule } from "@angular/service-worker";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { StreamingMedia } from "@ionic-native/streaming-media/ngx";
import { TooltipsModule } from "ionic4-tooltips";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { VideoPlayerPageModule } from "./pages/inside-app/media/videos/video-player/video-player.module";
import { environment } from "../environments/environment";
import { pageTransition } from "./page-transition";
import { PollModalPage } from "./pages/inside-app/poll-modal/poll-modal.page";
// import { NetworkService } from './providers/network/network.service';
import { FirebaseX } from "@ionic-native/firebase-x/ngx";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
@NgModule({
  imports: [
    GalleryModule,
    LightboxModule,
    BrowserAnimationsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LanguagesPopoverPageModule,
    HttpClientModule,
    LanguagesPopoverPageModule,
    FormsModule,
    VideoPlayerPageModule,
    FontAwesomeModule,
    IonicModule.forRoot({
      navAnimation: pageTransition,
      swipeBackEnabled: false,
      backButtonText: "",
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgxPopperModule.forRoot({
      // disableDefaultStyling: true
    }),
    TooltipsModule.forRoot(),
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
  ],
  declarations: [AppComponent, NotificationFilterPage, PollModalPage],
  providers: [
    Network,
    InAppBrowser,
    SplashScreen,
    StatusBar,
    StreamingMedia,
   // FCM,
    Push,
    Device,
    FirebaseX,
    // NetworkService,
    ScreenOrientation,
  ],
  bootstrap: [AppComponent],
  entryComponents: [NotificationFilterPage],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faFolder, faTrophy);
    // library.addIconPacks(fas, fab, far);
  }
}
